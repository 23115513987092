import Typography from "@mui/material/Typography";
import * as React from "react";
import {Variant} from "@lib/styles/types";

export type GenericTextProps<T> = {
    text: T
    id?: string
    className?: string
    variant?: Variant | 'inherit'
    component?: React.ElementType
    prefix?: JSX.Element
}

type PlainTextProps = GenericTextProps<string>

export const PlainText = ({text, prefix, ...props}: PlainTextProps) => (
    <Typography {...props}>
        {prefix}
        {
            text.replace(/([a-zA-Z0-9])--([a-zA-Z0-9])/g, '$1–$2') // En dash
                .replace(/ - /g, ' — ') // Em dash
        }
    </Typography>
)

type TextProps = GenericTextProps<string>

export const Text = ({text, ...props}: TextProps): JSX.Element => (
    <PlainText text={text} {...props} />
)
