import Page from "@components/page";
import StandardLayout from "@components/layout/standard-layout";
import React from "react";
import Hero from "@components/hero";

const FourOhFour = () => (
        <Page name='Page Not Found'>
            <StandardLayout>
                <Hero
                    title='Uh oh... something is missing.'
                    subtitle='The page you referred to could not be found.'
                >
                </Hero>
            </StandardLayout>
        </Page>
)

export default FourOhFour