import Typography from "@mui/material/Typography";
import React, {ReactNode} from "react";
import {Box, Container, styled} from "@mui/material";
import {Text} from "@components/text/text";

type StylesProps = {
    borderBottom?: boolean
    borderTop?: boolean
}

const containerStyles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    justifyItems: "center",
    alignItems: "center",
    padding: 8,
    '& > *': {
        paddingTop: 2,
        paddingBottom: 2,
    },
} as const

const Root = styled('div')(({theme})=>({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create(['color', 'background-color']),
}))

type Props = {
    variant?: 'h1' | 'h2' | 'h3' | 'h4'
    component?: 'h1' | 'h2' | 'h3' | 'h4'
    title?: React.ReactChild
    subtitle?: string
    lead?: string
    className?: string
    children?: ReactNode
} & StylesProps

const Hero = ({
                  title,
                  subtitle,
                  lead,
                  variant,
                  component,
                  children,
                  className,
                  borderBottom,
                  borderTop,
                  ...props
              }: Props) => (
    <Root>
        <Container maxWidth='md' sx={containerStyles}>
            <Typography variant={variant ?? 'h1'} component={component ?? variant ?? 'h1'} textAlign='center'>
                {title}
            </Typography>
            {subtitle && <Typography variant='subtitle1' component='div' textAlign='center'>{subtitle}</Typography>}
            {lead && (<Text text={lead}/>)}
            <Box py={2} display='flex' flexDirection='column' alignItems='center'>
                {children}
            </Box>
        </Container>
    </Root>
)

export default Hero